// ... Styles
import styles from './Cta.module.scss';

// ... React and Next.js
import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

// ... Utility
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

// ... Shared package components
import Column from '@shared/components/atoms/Column';
import Container from '@shared/components/atoms/Container';
import Row from '@shared/components/atoms/Row';
import Typography from '@shared/components/atoms/Typography';
import typeProduct from '@shared/utilities/types/Product';
const Product = dynamic(() => import('@shared/components/molecules/Product'));
const Search = dynamic(() => import('@shared/icons/maggie/Search'));
const Button = dynamic(() => import('@shared/components/atoms/Button'));

// ... Local Components 
const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Checks = dynamic(() => import('@/components/molecules/Checks'));
const Field = dynamic(() => import('@/components/atoms/Field'));
const IconsList = dynamic(() => import('@/components/molecules/IconsList'));
const Eventbrite = dynamic(() => import('@/components/molecules/Eventbrite'));

// Types and Constants
import { CTA_STRINGS } from '@/utilities/constants/Cta';
import typeCta from '@/utilities/types/Cta';

// Hooks and Contexts
import { useLocale } from '@/utilities/contexts/Locale';
import { GetProductLinks } from '@/utilities/helpers/GetProductLinks';

interface CtaProps {
  isShortcode?: boolean;
  className?: string;
  columns: 9 | 10 | 12;
  cta: typeCta;
  index: number;
  fontSizes?: {
    heading?: {
      xs: number,
      md: number,
      lg: number
    },
    text?: number
  }
}

const Cta = (props: CtaProps) => {
  const { isShortcode = false, className, columns, cta, index, fontSizes } = props;

  const locale: string = useLocale();

  return (
    <section
      className={cn(
        className,
        cta.customise.width === 'full' && styles[cta.customise.background],
        cta.customise.image && cta.customise.image?.mediaItemUrl && styles.withImage,
        styles.root,
        styles[cta.customise.width],
        {
          [styles.isShortcode]: isShortcode,
        },
      )}
      id={cta.id ? cta.id : `row-${index + 1}`}
    >
      <Container>
        <div
          className={cn(
            cta.customise.width === 'container' &&
              styles[cta.customise.background],
            styles.spacing,
            isShortcode && styles.viaShortcode
          )}
        >
          <Row justify="center" xs={2}>
            {cta.customise.image?.mediaItemUrl && (
              <Column xs={12} lg={5}>
                <div className={styles.image}>
                  <Image
                    src={cta.customise.image.mediaItemUrl}
                    alt={cta.customise.image?.altText ?? 'Section image'}
                    width={cta.customise.image?.mediaDetails.width}
                    height={cta.customise.image?.mediaDetails.height}
                  />
                </div>
              </Column>
            )}
            <Column
              xs={12}
              lg={cta.customise.image?.mediaItemUrl ? 7 : columns}
              className={styles.content}
            >
              {cta.content.map((content, index) => {

                const productLinks = content.product ? GetProductLinks(content.product) : null;

                return(
                  <Fragment key={index}>
                    {content.checks && (
                      <Checks checks={content.checks} className={styles.checks} />
                    )}
                    {content.iconsList && (
                      <IconsList
                        iconsList={content.iconsList}
                        className={styles.iconsList}
                      />
                    )}
                    {content?.eventbrite && content.eventbrite.id && (
                      <Eventbrite event={content.eventbrite} />
                    )}
                    {content.editor && (
                      <Typography className={styles.editor} xs={fontSizes && fontSizes.text ? fontSizes.text : 3}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content.editor),
                          }}
                        />
                      </Typography>
                    )}
                    {content.embed && (
                      <div
                        className={styles.embed}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.embed, {
                            ADD_TAGS: ['iframe'],
                            ADD_ATTR: [
                              'frameborder',
                              'height',
                              'width',
                              'allow',
                              'allowfullscreen',
                              'webkitallowfullscreen',
                              'mozallowfullscreen',
                              'oallowfullscreen',
                              'msallowfullscreen',
                            ],
                          }),
                        }}
                      />
                    )}
                    {content.links && (
                      <div className={styles.links}>
                        <Buttons
                          buttons={content.links}
                          size={isShortcode ? 'medium' : 'large'}
                        />
                      </div>
                    )}
                    {content.product && productLinks &&
                      <Product
                        alignment="center"
                        className={styles.product}
                        product={content.product}
                        branchLinks={{
                          appStore: productLinks.appStoreLink,
                          googlePlay: productLinks.googlePlayLink
                        }}
                        locale={locale}
                      />
                    }
                    {content.search && (
                      <form
                        action="https://public-library.safetyculture.io/search/"
                        method="get"
                        target="_blank"
                        className={styles.search}
                      >
                        <Field
                          className={styles.field}
                          name="q"
                          placeholder={content.search}
                          required={true}
                          tag="input"
                          type="search"
                        />
                        <Button
                          aria={CTA_STRINGS.search[locale]}
                          className={styles.button}
                          icon={true}
                          style="tertiary"
                          type="submit"
                        >
                          <Search height={20} width={20} />
                        </Button>
                      </form>
                    )}
                    {content.title && (
                      <Typography
                        className={styles.title}
                        family="poppins"
                        tag="h4"
                        xs={fontSizes && fontSizes?.heading ? fontSizes.heading.xs : 5}
                        md={fontSizes && fontSizes?.heading ? fontSizes.heading.md : 6}
                        lg={fontSizes && fontSizes?.heading ? fontSizes.heading.lg : 7}
                      >
                        {content.title}
                      </Typography>
                    )}
                  </Fragment>
                )
              })}
            </Column>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Cta;
